import { useState, useEffect } from "react";
import { Cardinfo } from "../components/CardInfo";
import { LabelMoreDetail } from "../components/LabelMoreDetail";
import Rectangle from "../components/img/Rectangle 12.svg";
import Footer from "../components/Footer";
import { Link, useParams } from "react-router-dom";
import useFetch from "../components/hooks/useFetch";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import "./news.css";

export const NewsDetails = () => {
  const { t } = useTranslation();
  const [showLatestNews, setShowLatestNews] = useState(false);
  const [fontSizeView, setFontSize] = useState(16); // Default font size
  const [fontSizeDate, setFontDate] = useState(16);
  const [fontSizeLabel, setFontLabel] = useState(14);

  useEffect(() => {
    const handleResize = () => {
      // Adjust font size based on screen width
      if (window.innerWidth <= 1180) {
        setFontSize(16);
        setFontDate(16);
        setFontLabel(8);
      } else {
        setFontSize(12);
        setFontDate(12);
        setFontLabel(14);
      }
    };

    // Listen for resize events
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the correct font size on component mount
    handleResize();

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { id } = useParams(); // Get the dynamic id from the URL
  const { data, isLoading, error } = useFetch(
    `https://strapi-cms-doxo.onrender.com/api/news/${id}?populate=*`
  );

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const newsItem = data.data;
  const { title, subtitle, maintext1, maintext2, video } = newsItem.attributes;

  const imageData = newsItem.attributes.image?.data?.[0]?.attributes;
  const imageUrl = imageData
    ? `https://strapi-cms-doxo.onrender.com${imageData.url}`
    : Rectangle; // Fallback if no image

  const extractVideoId = (url) => {
    if (!url) return null; // Return null if url is empty or undefined
    //todo: modify this:
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=)|youtu\.be\/)([^&\/\s]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };
  console.log(video);

  const videoId = extractVideoId(video);
  const embedVideoURL = videoId
    ? `https://www.youtube.com/embed/${videoId}`
    : null;
  console.log(embedVideoURL);

  // Function to render the content based on the structure of mainText1 and mainText2
  const renderContent = (contentArray) => {
    return contentArray.map((content, index) => {
      if (content.type === "paragraph") {
        return <p key={index}>{content.children[0].text}</p>;
      } else if (content.type === "heading") {
        return <h1 key={index}>{content.children[0].text}</h1>;
      } else {
        return null; // Handle other types or skip
      }
    });
  };

  const handleLabelClick = () => {
    setShowLatestNews(!showLatestNews);
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling effect
    });
  };

  return (
    <div className="news-content">
      {/*news detail section*/}
      <div className="frame-23">
        <div className="frame-24">
          <div className="frame-25">
            <div className="text-wrapper-21">{t("new")}</div>
          </div>
          <div className="frame-26">
            <div className="frame-27">
              <p className="m-s-AD">{title}</p>
              <p className="text-wrapper-22">{subtitle}</p>
            </div>
            <Cardinfo fontSizeView={fontSizeView} fontSizeDate={fontSizeDate} />
          </div>
        </div>

        <div className="frame-28">
          <hr className="line-2" />
          <div className="frame-29">
            <div className="frame-30">
              <img
                className="rectangle-2"
                alt={imageData?.alternativeText || "News image"}
                src={imageUrl}
              />
            </div>
            <div className="text-wrapper-24">{renderContent(maintext1)}</div>
          </div>

          {embedVideoURL && (
            <iframe
              className="img-wrapper"
              src={embedVideoURL}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}

          <div className="frame-32">
            <div className="text-wrapper-24">{renderContent(maintext2)}</div>
          </div>
        </div>
      </div>

      {/*latest news section*/}
      <div className="frame-19">
        <div className="frame-20">
          <hr className="line-3" />
          <div className="frame-21">
            <Link onClick={handleClick} to="/news">
              <LabelMoreDetail text={t("More")} />
            </Link>
            <div className="text-wrapper-20">{t("CoreLastestNews")}</div>
          </div>
        </div>
      </div>

      <Footer className="footer" />
    </div>
  );
};
