import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import Navbar from "./components/Navbar";
import { Home } from "./pages/Home";
import { AboutUs } from "./pages/AboutUs";
import { ContactUs } from "./pages/ContactUs";
import { Workspace } from "./pages/Workspace";
import { Experts } from "./pages/Experts";
import { CorePro } from "./pages/CorePro";
import { Entrepreneurship } from "./pages/EntrepreneurshipProgram";
import { News } from "./pages/News";
import { NewsDetails } from "./pages/NewsDetails";
import LanguageDirectionHandler from "./components/LanguageDirectionHandler";
import "./App.css";

const client = new ApolloClient({
  uri: "https://strapi-cms-doxo.onrender.com/graphql",
  cache: new InMemoryCache(),
});

function App() {
  return (
    <Router>
      <ApolloProvider client={client}>
        <LanguageDirectionHandler>
          <Navbar />
          <div className="content">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/news" element={<News />} />
              <Route exact path="/NewsDetails/:id" element={<NewsDetails />} />
              <Route exact path="/aboutus" element={<AboutUs />} />
              <Route exact path="/corepro" element={<CorePro />} />
              <Route
                exact
                path="/entrepreneurship"
                element={<Entrepreneurship />}
              />
              <Route exact path="/workspace" element={<Workspace />} />
              <Route exact path="/contactus" element={<ContactUs />} />
              <Route exact path="/experts" element={<Experts />} />
            </Routes>
          </div>
        </LanguageDirectionHandler>
      </ApolloProvider>
    </Router>
  );
}

export default App;
